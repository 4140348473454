import React from 'react'
import {Basic} from "../components/SEO";
import EbookForm from "../components/ebookForm";

const pages = (props) => {
    return (
        <>
            <Basic seo={{title: props.pageContext.book.name, description: props.pageContext.book.name}}/>
            <main>
                <EbookForm data={props.pageContext.book}/>
            </main>
        </>
    )
};

export default pages
