import React from "react";

function Popup(props) {

    return (
        <div className={'overlay-container ' + props.customClass} data-datocms-noindex>
            <div className={'overlay'} onClick={props.onOutSideClick ? props.onOutSideClick : props.onClose}></div>
            <div className={'popup-container'}>
                <div className={'close-icon'} onClick={props.onClose}><span>X</span></div>
                {props.children}
            </div>
        </div>
    );
}

export default Popup